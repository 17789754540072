import { mapActions, mapGetters } from "vuex"
import Table from "@/components/dashboard/Table.vue"
import ReceiptPage from "../ReceiptPage.vue"

export default {
  name: 'FinancePage',

  components: {
    ReceiptPage
  },

  data: () => ({
    snackbar: false,
    tempEnd: '',
    tempStart: '',
    menu1: false,
    menu2: false,
    params: {
      endDate: '',
      senderName: '',
      startDate: '',
      pageRequest: 1
    },
  }),
  mounted () {
    this.hideButton()
    this.fetchCustomers()
  },
  computed: {
    ...mapGetters('receipts',['getFilteredReceipt']),
    ...mapGetters('customers',['customersName']),
    formatStartDate () {
      return this.formatDate(this.tempStart)
    },
    formatEndDate () {
      return this.formatDate(this.tempEnd)
    },
  },
  methods: {
    ...mapActions('receipts',['excelReceipt', 'filteredReceipt']),
    ...mapActions('common',['hideButton']),
    ...mapActions('customers',['fetchCustomers']),
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    epoch (value) {
      const date = new Date(value)
      return Date.parse(date)
    },
    exportExcel () {
      this.params.endDate = this.epoch(this.tempEnd)
      this.params.startDate = this.epoch(this.tempStart)
      this.filteredReceipt(this.params)
      setTimeout(() => {
        (this.getFilteredReceipt.length > 0) ? this.excelReceipt(this.params) : this.snackbar = true
      }, 500);
    },
    clearFilters () {
      this.tempEnd = ''
      this.tempStart = ''
      this.params.senderName= ''
    },
  }
}