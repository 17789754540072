import router from '@/router'
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'EditAdmin',
  data: ( ) => ({
    self: false,
    valid: false,
    showPass: false,
    adminNameRules: [
      v => !!v || 'Nama harus diisi',
      v => (v && v.length > 2) || 'Nama harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Nama tidak boleh lebih dari 50 huruf'
    ],
    usernameRules: [
      v => !!v || 'Username harus diisi',
      v => (v && v.length > 2) || 'Username harus lebih dari 2 huruf',
      v => (v || '').indexOf(' ') < 0 ||'Username tidak boleh menggunakan spasi',
      v => (v && v.length < 51) || 'Username tidak boleh lebih dari 50 huruf'
    ],
    roleRules: [
      v => !!v || 'Role harus dipilih',
    ],
    role: ['admin', 'boss'],
    changePass: ''
  }),
  mounted () {
    this.fetchAdmin(this.$route.params.username)
    setTimeout(() => {
      this.init()
    }, 100);
  },
  computed: {
    ...mapGetters('admins', ['getAdmin']),
    ...mapGetters(['currentUser'])
  },
  methods: {
    ...mapActions('admins', ['fetchAdmin', 'editAdmin']),
    init () {
      if(this.currentUser.adminId == this.getAdmin.adminId) this.self = true
    },
    save () {
      if(this.$refs.form.validate()) {
        this.editAdmin({
          id: this.getAdmin.adminId,
          data : {
            adminName: this.getAdmin.adminName,
            adminPass: this.changePass,
            adminRole: this.getAdmin.adminRole,
            adminUser: this.getAdmin.adminUser
          }
        })
        window.location.pathname = '/admins'
      }
    },
    backHome() {
      router.push({ path: '/admins' })
    },
  }
}