import router from '@/router'
import { mapActions } from "vuex";

export default {
  name: 'CreateAdmin',
  data: ( ) => ({
    valid: false,    
    adminNameRules: [
      v => !!v || 'Nama harus diisi',
      v => (v && v.length > 2) || 'Nama harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Nama tidak boleh lebih dari 50 huruf'
    ],
    usernameRules: [
      v => !!v || 'Username harus diisi',
      v => (v && v.length > 2) || 'Username harus lebih dari 2 huruf',
      v => (v || '').indexOf(' ') < 0 ||'Username tidak boleh menggunakan spasi',
      v => (v && v.length < 51) || 'Username tidak boleh lebih dari 50 huruf'
    ],
    passwordRules: [
      v => !!v || 'Kata Sandi harus diisi',
      v => (v && v.length > 5) || 'Kata sandi harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Kata Sandi tidak boleh lebih dari 255 huruf'
    ],
    roleRules: [
      v => !!v || 'Role harus dipilih',
    ],
    role: ['admin','boss'],
    newAdmin: {
      adminName: "",
      adminUser: "",
      adminPass: "",
      adminRole: ""
    },
    showPass: false,
  }),
  methods: {
    ...mapActions('admins', ['createAdmin']),
    create () {
      if(this.$refs.form.validate()) {
        this.createAdmin(this.newAdmin)
        window.location.pathname = '/admins'
      }
    },
    backHome() {
      router.push({ path: '/admins' })
    },
  }
}