import AdminsApi from '@/api/admins'

export const state = {
  allAdmins: [],
  admin: []
}
export const getters = {
  allAdmins (state) {
    return state.allAdmins
  },
  getAdmin (state) {
    return state.admin
  },
  getTotalAdmin (state) {
    return state.admin.length
  }
}
export const mutations = {
  setAdmins(state, value) {
    state.allAdmins = value
  },
  setAdmin(state, value) {
    state.admin = value
  },
}
export const actions = {
  fetchAdmins ({ commit }) {
    AdminsApi.getAdmins().then((response) => {
      commit('setAdmins', response.data.admins)
    })
  },
  fetchAdmin ({ commit }, username) {
    AdminsApi.getAdmin(username).then((response) => {    
      commit('setAdmin', response.data.admin)
    })
  },
  editAdmin ({ commit }, { id, data }) {
    AdminsApi.putAdmin(id, data)
  },
  createAdmin ({ commit }, data) {
    AdminsApi.postAdmin(data)
  },
  deleteAdmin ({commit}, id) {
    AdminsApi.deleteAdmin(id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}