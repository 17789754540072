import router from "@/router";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TrackCard",
  data: () => ({
    receiptCode : "",
    checkReceipt: false,
  }),
  computed: {
    ...mapGetters('receipts',['getReceipt'])
  },
  methods: {
    ...mapActions('receipts',['fetchReceipt']),
    findReceipt () {
      this.fetchReceipt(this.receiptCode)
      setTimeout(() => {
        (this.getReceipt.createDate != null) ? router.push('/track/' + this.receiptCode) : this.checkReceipt = true
      }, 500);
    }
  }
};