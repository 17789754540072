import Table from "@/components/dashboard/Table.vue"
import { mapActions, mapGetters } from "vuex"


export default {
  name: 'CustomerPage',

  components: {
    Table
  },

  data: ( ) => ({
    headers: [
      { text: 'Nama', value: 'customerName'},
      { text: 'Alamat', value: 'customerAddress' },
      { text: 'Penanggung Jawab', value: 'supervisorName' },
      { text: 'No. HP', value: 'supervisorContact' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    editedItem: {},
    defaultItem: {
      customerId: "",
      customerName: "",
      customerAddress: "",
      customerEmail: "",
      customerPass: "",
      supervisorName: "",
      supervisorContact: ""
    }
  }),
  mounted () {
    this.fetchCustomers()
  },
  computed: {
    ...mapGetters('customers',['allCustomers', 'getTotalCustomer']),
    getTotalPage () {
      return Math.ceil(this.getTotalCustomer/10)
    }
  },
  methods: {
    ...mapActions('customers',['fetchCustomers'])
  }
}