import Login from "../LoginCard.vue"

export default {
  name: "Header",

  components: {
    Login,
  },

  data: () => ({
    items: [
      { title: 'Lacak Resi', click: 'track-card', up: 150},
      { title: 'Cek Tarif', click: 'tarif', up: 120},
      { title: 'Customer Service', click: 'customer-service', up: 0}
    ],
  }),

  methods: {
    scroll(value, up) {
      const element = document.getElementById(value);
      window.scroll({
        top: element.offsetTop - up,
        behavior: 'smooth'
      });
    },
  }
};