export default {
  api: {
    receipts: page => 'http://51.79.186.90:1234/trackin-bo/receipt/all?pageRequest=' + page,
    receipt: code => 'http://51.79.186.90:1234/trackin-bo/receipt/receiptNumber?receiptNumber=' + code,
    editReceipt: code => 'http://51.79.186.90:1234/trackin-bo/receipt/edit/' + code,
    createOrder: 'http://51.79.186.90:1234/trackin-bo/order/create',
    deleteReceipt: id => 'http://51.79.186.90:1234/trackin-bo/receipt/delete/' + id,
    pdfReceipt: code => 'http://51.79.186.90:1234/trackin-bo/receipt/print?receiptNumber=' + code,
    updateStatus: code => 'http://51.79.186.90:1234/trackin-bo/status/update/' + code,
    
    customers: 'http://51.79.186.90:1234/trackin-bo/customer/all',
    editCustomer: id => 'http://51.79.186.90:1234/trackin-bo/customer/edit/' + id,
    createCustomer: 'http://51.79.186.90:1234/trackin-bo/customer/add',
    deleteCustomer: id => 'http://51.79.186.90:1234/trackin-bo/customer/delete/' + id,
    customerDetail: email => 'http://51.79.186.90:1234/trackin-bo/customer/details?customerEmail=' + email,
    
    admins: 'http://51.79.186.90:1234/trackin-bo/admin/all',
    admin: username => 'http://51.79.186.90:1234/trackin-bo/admin/details?adminUser=' + username,
    createAdmin: 'http://51.79.186.90:1234/trackin-bo/admin/add',
    deleteAdmin: id => 'http://51.79.186.90:1234/trackin-bo/admin/delete/' + id,
    editAdmin: id => 'http://51.79.186.90:1234/trackin-bo/admin/edit/' + id,

    login: (params) => `http://51.79.186.90:1234/trackin-bo/user/login?password=${params.userPassword}&userEmail=${params.userEmail}`
  }
}