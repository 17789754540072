import ReceiptsApi from '@/api/receipts'
import httpApi from '@/util/http-api'

export const state = {
  allReceipts: [],
  receipt: [],
  filteredReceipt: [],
  pageAll: []
}
export const getters = {
  allReceipts (state) {
    return state.allReceipts.receipts
  },
  getReceipt (state) {
    return state.receipt
  },
  getFilteredReceipt (state) {
    return state.filteredReceipt
  },
  getTotalPage (state) {
    return state.allReceipts.totalPage
  },
}
export const mutations = {
  setReceipts(state, value) {
    state.allReceipts = value
  },
  setReceipt(state, value) {
    state.receipt = value
  },
  setFilteredReceipt(state, value) {
    state.filteredReceipt = value
  },

}
export const actions = {
  fetchReceipts ({ commit }, page) {
    ReceiptsApi.getReceipts(page).then((response) => {
      commit('setReceipts', response.data)
    })
  },
  fetchReceipt ({ commit }, code) {
    ReceiptsApi.getReceipt(code).then((response) => {
      commit('setReceipt', response.data.receipt)
    })
  },
  printReceipt ({ commit }, code) {
    window.open(ReceiptsApi.printReceipt(code))
  },
  excelReceipt ({ commit }, params) {
    const { endDate, senderName, startDate } = params
    let param = []
    endDate && param.push(`endDate=${endDate}`)
    senderName && param.push(`senderName=${senderName}`)
    startDate && param.push(`startDate=${startDate}`)
    return window.open(`http://51.79.186.90:1234/trackin-bo/receipt/export?${param.join('&')}`)
  },
  filteredReceipt ({ commit }, params) {
    const {pageRequest,endDate, senderName, startDate } = params
    let param =[`pageRequest=${pageRequest}`]
    endDate && param.push(`endDate=${endDate}`)
    senderName && param.push(`senderName=${senderName}`)
    startDate && param.push(`startDate=${startDate}`)
    httpApi.getDataViaApi(`http://51.79.186.90:1234/trackin-bo/receipt/filtered?${param.join('&')}`)
    .then((response) => {
      commit('setFilteredReceipt', response.data.receipts)
    })
  },
  updateStatus ({ commit }, { code, data }) {
    ReceiptsApi.updateStatus(code, data)
  },
  editReceipt ({ commit }, { code, data }) {
    ReceiptsApi.putReceipt(code, data)
  },
  createReceipt ({ commit }, data) {
    ReceiptsApi.postReceipt(data)
  },
  deleteReceipt ({ commit }, code) {
    ReceiptsApi.deleteReceipt(code)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}