import { render, staticRenderFns } from "./CustomerDetail.vue?vue&type=template&id=28b485e8&scoped=true&"
import script from "./js/customer-detail.js?vue&type=script&lang=js&"
export * from "./js/customer-detail.js?vue&type=script&lang=js&"
import style0 from "./CustomerDetail.vue?vue&type=style&index=0&id=28b485e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b485e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VChip,VDataTable,VIcon,VPagination,VSimpleTable,VSpacer,VToolbar,VToolbarTitle})
