import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import CustomerCreate from '../pages/CustomerCreate.vue'
import CustomerDetail from '../pages/CustomerDetail.vue'
import CustomerEdit from '../pages/CustomerEdit.vue'
import CustomersPage from '../pages/CustomersPage.vue'
import ReceiptCreate from '../pages/ReceiptCreate.vue'
import ReceiptDetail from '../pages/ReceiptDetail.vue'
import ReceiptEdit from '../pages/ReceiptEdit.vue'
import ReceiptPage from '../pages/ReceiptPage.vue'
import TrackReceipt from '../pages/TrackReceipt.vue'
import FinancePage from '../pages/FinancePage.vue'
import AdminPage from '../pages/AdminPage.vue'
import AdminEdit from '../pages/AdminEdit.vue'
import AdminCreate from '../pages/AdminCreate.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/track/:code',
    name: 'track-receipt',
    component: TrackReceipt
  },
  {
    path: '/receipts',
    name: 'receipt-page',
    component: ReceiptPage,
  },
  {
    path: '/receipt/:code',
    name: 'receipt-detail',
    component: ReceiptDetail
  },
  {
    path: '/create-receipt',
    name: 'create-receipt',
    component: ReceiptCreate
  },
  {
    path: '/edit-receipt/:code',
    name: 'edit-receipt',
    component: ReceiptEdit,
  },
  {
    path: '/customers',
    name: 'customers-page',
    component: CustomersPage
  },
  {
    path: '/create-customer',
    name: 'create-customer',
    component: CustomerCreate
  },
  {
    path: '/edit-customer/:id',
    name: 'edit-customer',
    component: CustomerEdit
  },
  {
    path: '/customer/:email',
    name: 'customer-detail',
    component: CustomerDetail
  },
  
  {
    path: '/admins',
    name: 'admins',
    component: AdminPage
  },
  {
    path: '/edit-admin/:username',
    name: 'edit-admin',
    component: AdminEdit
  },
  {
    path: '/create-admin',
    name: 'create-admin',
    component: AdminCreate
  },
  {
    path: '/finance',
    name: 'finance',
    component: FinancePage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name !== 'home' && to.name !== 'track-receipt' && !store.state.isLoggedIn) next({ name: 'home'})
  else next()
  
  if(!!store.state.currentUser.customerId && to.name != 'customer-detail' && to.name != 'create-receipt' && to.name != 'receipt-detail') next('/customer/' + store.state.currentUser.customerEmail)
  if(!!store.state.currentUser.adminRole && store.state.currentUser.adminRole != 'boss' && (to.name == 'finance' || to.name == 'admins' || to.name == 'edit-admin' || to.name == 'create-admin')) next({name: 'receipt-page'})
})

export default router
