import { mapActions, mapGetters } from "vuex"
import Table from "@/components/dashboard/Table.vue"

export default {
  name: 'AdminPage',

  components: {
    Table
  },

  data: () => ({ 
    headers: [
      { text: 'Nama', value: 'adminName'},
      { text: 'Username', value: 'adminUser'},
      { text: 'Role', value: 'adminRole' },
      { text: 'Action', value: 'actions' },
    ],
  }),
  mounted () {
    this.fetchAdmins()
  },
  computed: {
    ...mapGetters('admins',['allAdmins', 'getTotalAdmin']),
    getTotalPage () {
      return Math.ceil(this.getTotalAdmin/10)
    }
  },
  methods: {
    ...mapActions('admins',['fetchAdmins']),
  }
}