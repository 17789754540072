import { mapActions, mapGetters } from "vuex"
import Table from "@/components/dashboard/Table.vue"
import router from "@/router"

export default {
  name: 'ReceiptPage',

  components: {
    Table
  },

  data: () => ({
    headers: [],
    editedItem: {},
    defaultItem: {
      receiptNumber: '',
      createDate: '',
      dueDate: '',
      customerId: '',
      adminId: '',
      senderName: '',
      senderContact: '',
      senderAddress: '',
      senderPic: '',
      receiverName: '',
      receiverContact: '',
      receiverAddress: '',
      postalCode: '',
      origin: '',
      destination: '',
      description: '',
      value: '',
      unit: '',
      price: '',
      updateDate: '',
      status: 0,
      statusDescription: '',
      truckNumber:  ''
    }
  }),
  mounted () {
    this.fetchReceipts(1)
    this.init()
  },
  computed: {
    ...mapGetters('receipts',['allReceipts', 'getCurrentPage', 'getTotalPage']),
    ...mapGetters(['currentUser']),
    isCustomer () {
      return !!this.currentUser.customerId ? true : false
    }
  },
  methods: {
    ...mapActions('receipts',['fetchReceipts']),
    init () {
      if (window.location.pathname == '/receipts'){
        this.headers = [
          { text: 'Tanggal', value: 'createDate'},
          { text: 'Resi', value: 'receiptNumber' },
          { text: 'Asal', value: 'origin' },
          { text: 'Tujuan', value: 'destination' },
          { text: 'Pengirim', value: 'senderName' },
          { text: 'Penerima', value: 'receiverName' },
          { text: 'Harga', value: 'price' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
      }
      else if(window.location.pathname == '/finance') {
        this.headers = [
          { text: 'Resi', value: 'receiptNumber'},
          { text: 'Pengirim', value: 'senderName'},
          { text: 'Asal', value: 'origin' },
          { text: 'Tujuan', value: 'destination' },
          { text: 'Tanggal Resi', value: 'createDate' },
          { text: 'Harga', value: 'price' },
          { text: 'Status', value: 'status' },
        ]
      }
    },
    next (page) {
      this.fetchReceipts(page)
    }
  }
}