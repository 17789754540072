import axios from 'axios'

let STATUS = true
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status != 200){
      STATUS = false
    }
  }
)

function getStatus () {
  return STATUS
}

export default {
  getDataViaApi (path) {
    return axios.get(path)
  },

  postDataViaApi (path, data) {
    axios.post(path, data)
  },

  deleteDataViaApi (path) {
    axios.delete(path)
  },

  putDataViaApi (path, data) {
    axios.put(path, data)
  },
  getStatus
}