import { mapActions, mapGetters } from "vuex";

export default {
  name: "Drawer",
  data: () => ({
    role:'',
    drawer: true,
    items: [],
    mini: true
  }),
  mounted() {
    this.getRole()
  },
  computed: {
    ...mapGetters(['currentUser']),
    name () {
      return !!this.currentUser.adminName ? this.currentUser.adminName : this.currentUser.customerName
    },
    title () {
      if(this.role == 'boss') return 'Boss'
      else if(this.role == 'admin') return 'Administrator'
      else if(this.role == 'cust') return 'Customer'
    }
  },
  methods: {
    ...mapActions(['logout']),
    getRole() {
      if(this.currentUser.adminRole == 'boss'){
        this.role = 'boss'
        this.items = [
          { text: 'Resi', icon: 'mdi-file-outline', path: '/receipts'},
          { text: 'Customer', icon:'mdi-account-outline', path: '/customers'},
          { text: 'Karyawan', icon:'mdi-badge-account-horizontal-outline', path: '/admins'},
          { text: 'Keuangan', icon:'mdi-cash-multiple', path: '/finance'},
        ]
      }
      else if(this.currentUser.adminRole == 'admin'){
        this.role = 'admin'
        this.items = [
          { text: 'Resi', icon: 'mdi-file-outline', path: '/receipts'},
          { text: 'Customer', icon:'mdi-account-outline', path: '/customers'}
        ]
      }
      else if(!!this.currentUser.customerId){
        this.role = 'cust'
        this.items = [
          { text: 'Resi', icon: 'mdi-file-outline', path: '/receipts'}
        ]
      }
    },
    isLogout () {
      this.logout()
      window.location.pathname = '/'
    }
  }
};