import { mapActions, mapGetters } from 'vuex'
import Header from "@/components/home/Header.vue"
import Banner from "@/components/home/Banner.vue"
import TrackCard from "@/components/home/TrackCard.vue"

export default {
  name: 'Home',

  components: {
    Header,
    Banner,
    TrackCard
  },

  data: () => ({
    city: [
      "Bandung",
      "Jakarta",
      "Tangerang",
      "Tasikmalaya",
      "Ciamis",
      "Cirebon",
      "Tegal",
      "Purwokerto",
      "Banjarnegara",
      "Kebumen",
      "Purworejo",
      "Yogyakarta",
      "Solo",
      "Sukoharjo",
      "Semarang",
      "Madiun",
      "Ngawi",
      "Banyuwangi",
      "Surabaya",
      "Sidoarjo",
      "Probolinggo",
      "Malang",
      "Kediri",
      "Nganjuk",
      "Jombang",
      "Mojokerto",
      "Kertosono",
      "Bojonegoro"
    ],
    origin:'', //buat data v-model
    destination:'', //buat data v-model
    price:'',
    pricedesc:'',
    visible: false
  }),
  computed: {
    ...mapGetters('common',['visibleDrawer', 'pricelist'])
  },
  methods: {
    ...mapActions('common',['hideDrawer', 'fetchPricelist']),
    cekTarif () {
      for (let pricelist of this.pricelist){
        let { origin, destination, price } = pricelist;
        if (origin === this.origin && destination === this.destination){
          this.price = price;
          this.pricedesc = '*harga berikut adalah harga perkiraan, harga dapat berubah sewaktu-waktu';
          this.visible = true;
        }
        else if ( origin === this.destination && destination === this.origin){
          this.price = price;
          this.pricedesc = '*harga berikut adalah harga perkiraan, harga dapat berubah sewaktu-waktu';
          this.visible = true;
        } 
        else if (origin !== this.origin && origin !== this.destination && destination !== this.destination && destination !== this.origin) {
          this.price = 'Untuk kota yang dituju belum ada perkiraan harga, silakan hubungi Customer Service kami.';
          this.pricedesc = '';
          this.visible = true;
        }
      }
    },
    deletePrice () {
      this.price = '';
      this.pricedesc = '';
      this.visible = false;
    }
  },
  mounted () {
    this.hideDrawer()
    this.fetchPricelist()
  }
}