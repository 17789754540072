import router from '@/router'
import SimpleTable from '@/components/dashboard/SimpleTable.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrackReceipt',

  components: {
    SimpleTable
  },
  
  data: () => ({
    timeline:[]
  }),

  mounted () {
    this.hideDrawer()
    this.scrollToTop()
    setTimeout(() => {
      this.getItem()
    }, 100);
  },
  computed: {
    ...mapGetters('common',['visibleDrawer']),
    ...mapGetters('receipts',['getReceipt'])
  },
  methods: {
    ...mapActions('common',['hideDrawer']),
    backHome () {
      router.push({ path: '/' })
    },
    getItem () {
      const tanggal = this.getReceipt.updateDate.split(',')
      const desc = this.getReceipt.statusDescription.split(',')
      for (var i=0; i<tanggal.length; i++){
        const obj = {}
        obj['tanggal'] = tanggal[i]
        obj['desc'] = desc[i]
        this.timeline.push(obj)
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}