import config from '@/config'
import httpApi from '@/util/http-api'

export default {
  getCustomers () {
    return httpApi.getDataViaApi(config.api.customers)
  },
  getCustomer (email) {
    return httpApi.getDataViaApi(config.api.customerDetail(email))
  },
  putCustomer (id, data) {
    httpApi.putDataViaApi(config.api.editCustomer(id), data)
  },
  postCustomer (data) {
    httpApi.postDataViaApi(config.api.createCustomer, data)
  },
  deleteCustomer (id) {
    httpApi.deleteDataViaApi(config.api.deleteCustomer(id))
  }
}