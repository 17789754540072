import CustomersApi from '@/api/customers'

export const state = {
  allCustomers: [],
  customer: []
}
export const getters = {
  allCustomers (state) {
    return state.allCustomers
  },
  customersName (state) {
    return state.allCustomers.map(element => element.customerName)
  },
  getCustomer (state) {
    return state.customer
  },
  getTotalCustomer (state) {
    return state.allCustomers.length
  }
}
export const mutations = {
  setCustomers(state, value) {
    state.allCustomers = value
  },
  setCustomer(state, value) {
    state.customer = value
  },
}
export const actions = {
  fetchCustomers ({ commit }) {
    CustomersApi.getCustomers().then((response) => {
      commit('setCustomers', response.data.customers)
    })
  },
  fetchCustomer ({ commit }, email) {
    CustomersApi.getCustomer(email).then((response) => {    
      commit('setCustomer', response.data.customer)
    })
  },
  editCustomer ({ commit }, { id, data }) {
    CustomersApi.putCustomer(id, data)
  },
  createCustomer ({ commit }, data) {
    CustomersApi.postCustomer(data)
  },
  deleteCustomer ({commit}, id) {
    CustomersApi.deleteCustomer(id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}