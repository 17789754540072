import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginCard",
  data: () => ({
    dialog: false,
    showPass: false,
    wrongLogg: false,
    loading: false,
    param: {
      userEmail:'',
      userPassword:''
    },
    emailRules: [
      v => !!v || 'Email / Username harus diisi',
    ],
    passwordRules: [
      v => !!v || 'Kata Sandi harus diisi',
    ]
  }),
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  methods:{
    ...mapActions(['fetchCurrentUser', 'logout']),
    openDialog ( ){
      this.dialog = true;
      this.$refs.form.resetValidation()
      if(this.param.userEmail || this.param.userPassword){
        this.$refs.form.reset()
      }
    },
    login () {
      if(this.$refs.form.validate()) {
        this.loading = true
        this.fetchCurrentUser(this.param)
        setTimeout(() => {
          if(this.isLoggedIn){
            if(!!this.currentUser.adminRole){
              window.location.pathname = '/receipts'
              this.loading = false
            }
            else if(!!this.currentUser.customerId){
              window.location.pathname = '/customer/' + this.currentUser.customerEmail
            this.loading = false
            }
          }
          else {
            this.wrongLogg = true
            this.loading = false
          }
        }, 1000);
      }
    },
    isLogout () {
      this.logout()
      window.location.pathname = '/'
    }
  }
};