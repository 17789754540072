import router from '@/router'
import SimpleTable from '@/components/dashboard/SimpleTable.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceiptDetail',
  components: {
    SimpleTable
  },
  data: () => ({
    detail: {
      "Nomor Resi":"",
      "Pengirim":"",
      "Penanggung Jawab":"",
      "Kontak Pengirim":"",
      "Alamat Pengirim":"",
      "Penerima":"",
      "Kontak Penerima":"",
      "Alamat Penerima":"",
      "Kode Pos": "",
      "Kota Asal": "",
      "Kota Tujuan": "",
      "Deskripsi": "",
      "Harga barang": "",
      "Koli / Jumlah Barang": "",
      "Harga": "",
      "Status": "",
      "Administrator": "",
      "Nomor Truck": "",
    }
    
  }),
  mounted () {
    this.fetchReceipt(this.$route.params.code)
    this.fetchAdmins()
    setTimeout(() => {
      this.init()
    }, 100);
  },
  computed: {
    ...mapGetters('receipts',['getReceipt']),
    ...mapGetters('admins',['allAdmins'])
  },
  methods: {
    ...mapActions('receipts',['fetchReceipt', 'printReceipt']),
    ...mapActions('admins',['fetchAdmins']),
    init () {
      this.detail['Nomor Resi'] = this.getReceipt.receiptNumber
      this.detail["Pengirim"] = this.getReceipt.senderName
      this.detail["Penanggung Jawab"] = this.getReceipt.senderPic
      this.detail["Kontak Pengirim"] = this.getReceipt.senderContact
      this.detail["Alamat Pengirim"] = this.getReceipt.senderAddress
      this.detail["Penerima"] = this.getReceipt.receiverName
      this.detail["Kontak Penerima"] = this.getReceipt.receiverContact
      this.detail["Alamat Penerima"] = this.getReceipt.receiverAddress
      this.detail["Kode Pos"] = this.getReceipt.postalCode
      this.detail["Kota Asal"] = this.getReceipt.origin
      this.detail["Kota Tujuan"] = this.getReceipt.destination
      this.detail["Deskripsi"] = this.getReceipt.description
      this.detail["Harga barang"] = "Rp. " + this.getReceipt.value
      this.detail["Koli / Jumlah Barang"] = this.getReceipt.unit
      this.detail["Harga"] = "Rp. " + this.getReceipt.price
      this.detail["Status"] = this.getStatus(this.getReceipt.status)
      this.detail["Administrator"] = this.getAdmin(this.getReceipt.adminId)
      this.detail["Nomor Truck"] = this.getReceipt.truckNumber
    },
    cetak () {
      this.printReceipt(this.$route.params.code)
    },
    backHome(){
      router.push({ path: '/receipts' })
    },
    getStatus (status) {
      if (status == 1) return "Menunggu Pickup"
      else if (status == 2) return 'Dalam Pengiriman'
      else if (status == 3) return 'Selesai'
    },
    getAdmin (adminId) {
      const admin = this.allAdmins.find(admins => admins.adminId == adminId)
      return admin.adminName
    }
  }
}