import { mapActions, mapGetters } from "vuex"
import httpApi from "@/util/http-api";

export default {
  name: "Table",
  data: () => ({
    isCustomerPage: false,
    isReceiptPage: false,
    isAdminPage: false,
    isFinancePage: false,
    dialogUpdate: false,
    dialogDelete: false,
    deleted: '',
    selfCheck : false,
    page: 1,
    itemPerPage: 10,
    snackbarDelete: false,
    updateRule: [
      v => !!v || 'Deskripsi harus diisi',
      v => (v && v.length > 5) || 'Deskripsi harus lebih dari 5 huruf',
      v => (v || '').indexOf(',') < 0 || 'Deskripsi Status tidak boleh menggunakan tanda koma '
    ],
    status: [
      {text:'Menunggu Pickup', value:'1'},
      {text:'Dalam Pengiriman', value:'2'},
      {text:'Selesai', value:'3'},
    ],
  }),
  props: {
    headers: Array,
    items: Array,
    editedItem: Object,
    defaultItem: Object,
    pageCount: Number
  },
  mounted () {
    this.showButton()
    this.init()
  },
  watch: {
    dialogUpdate (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  computed: {
    ...mapGetters('common',['visibleButton']),
    ...mapGetters(['currentUser']),
    tableTitle () {
      if (this.isReceiptPage) return 'Tabel Resi' 
      else if (this.isCustomerPage) return 'Tabel  Customer'
      else if (this.isAdminPage) return 'Tabel  Karyawan'
      else if (this.isFinancePage) return 'Tabel  Keuangan'
    },
    buttonTitle () {
      if (this.isReceiptPage) return 'Tambah Resi'
      else if (this.isCustomerPage) return 'Tambah Customer'
      else if (this.isAdminPage) return 'Tambah Karyawan'
    },
    isBoss () {
      return this.currentUser.adminRole == 'boss' ? true : false
    },
  },
  methods: {
    ...mapActions('common',['showButton']),
    ...mapActions('customers',['deleteCustomer']),
    ...mapActions('receipts',['updateStatus', 'deleteReceipt', 'fetchReceipts']),
    ...mapActions('admins',['deleteAdmin']),
    init() {
      if (window.location.pathname == "/receipts") this.isReceiptPage = true
      else if (window.location.pathname == "/customers") this.isCustomerPage = true
      else if (window.location.pathname == "/admins") this.isAdminPage = true
      else if (window.location.pathname == "/finance") this.isFinancePage = true
    },
    detailItem (item) {
      if (this.isReceiptPage) window.open('/receipt/' + item.receiptNumber)
      else if (this.isCustomerPage) window.open('/customer/' + item.customerEmail)
    },
    updateItem (item) {
      this.editedItem.receiptNumber = item.receiptNumber
      this.dialogUpdate = true
    },
    editItem (item) {
      this.getRouteEdit(item)
    },
    deleteItem (item) {
      this.editedItem = Object.assign({}, item)
      this.deleteText()
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      if (this.isCustomerPage) {
        this.deleteCustomer(this.editedItem.customerId)
      }
      else if (this.isReceiptPage) {
        this.deleteReceipt(this.editedItem.receiptNumber)
      }
      // else if (this.isAdminPage) {
      //   if((this.items.filter(item => item.adminRole == 'boss').length) > 1)
      //     this.deleteAdmin(this.editedItem.adminId)
      //   else if((this.items.filter(item => item.adminRole == 'boss').length) <= 1)
      //     this.selfCheck = true
      // }
      else if (this.isAdminPage) {
        if(this.editedItem.adminId != this.currentUser.adminId)
          this.deleteAdmin(this.editedItem.adminId)
        else if(this.editedItem.adminId == this.currentUser.adminId)
          this.selfCheck = true
      }
      this.closeDelete()
      setTimeout(() => {
        this.snackbarDelete = true
      }, 100);
      
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    },
    close () {
      this.dialogUpdate = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    save () {
      this.updateStatus({
        code: this.editedItem.receiptNumber,  
        data: {
          status: this.editedItem.status,
          statusDescription: this.editedItem.statusDescription
        }
      })
      this.close()
      setTimeout(() => {
        window.location.reload()
      },500)
    },
    deleteText () {
      if (this.isReceiptPage) this.deleted = this.editedItem.receiptNumber
      else if (this.isCustomerPage) this.deleted = this.editedItem.customerName
      else if (this.isAdminPage) this.deleted = this.editedItem.adminName
    },
    snackbarMessage () {
      if(!httpApi.getStatus()) return 'Data ' + this.deleted + ' gagal dihapus'
      else if(httpApi.getStatus()) return 'Data ' + this.deleted + ' berhasil dihapus'
    },
    getSnackbarColor () {
      if(!httpApi.getStatus()) return '#DC3545'
      else if(httpApi.getStatus()) return '#28A745'
    },
    getStatus (status) {
      if (status == 1) return "Menunggu Pickup"
      else if (status == 2) return 'Dalam Pengiriman'
      else if (status == 3) return 'Selesai'
    },
    getColor (status) {
      if (status == 1) return '#DC3545'
      else if (status == 2) return '#FFC107'
      else if (status == 3) return '#28A745'
    },
    getRouteCreate () {
      if (this.isReceiptPage) window.location.pathname = '/create-receipt'
      else if (this.isCustomerPage) window.location.pathname = '/create-customer'
      else if (this.isAdminPage) window.location.pathname = '/create-admin'
    },
    getRouteEdit (item) {
      if (this.isReceiptPage) window.location.pathname = '/edit-receipt/' + item.receiptNumber
      else if (this.isCustomerPage) window.location.pathname = '/edit-customer/' + item.customerEmail
      else if (this.isAdminPage) window.location.pathname = '/edit-admin/' + item.adminUser
    },
    getFormat (item) {
      const date = new Date(item.createDate).getDate()
      const month = new Date(item.createDate).toLocaleString('default', { month: 'long' })
      const year = new Date(item.createDate).getFullYear()
      return (date + ' ' + month + ' ' + year)
    },
    sendToParent (page) {
      this.$emit('get-page', page)
    }
  }
};