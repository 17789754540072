import router from '@/router'
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'CreateReceipt',

  components: {
    
  },

  data: ( ) => ({
    valid: false,
    senderNameRules: [
      v => !!v || 'Pengirim harus dipilih'
    ],
    senderPicRules: [
      v => !!v || 'Penanggung Jawab harus diisi',
      v => (v && v.length > 2) || 'Penanggung Jawab harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Penanggung Jawab tidak boleh lebih dari 50 huruf'
    ],
    senderContactRules: [
      v => !!v  || 'Kontak Pengirim harus diisi',
      v => /^\d+$/.test(v) || 'Kontak Pengirim harus berupa angka',
      v => (v && v.length > 9) || 'Kontak Pengirim harus lebih dari 9 angka',
      v => (v && v.length < 16) || 'Kontak Pengirim tidak boleh lebih dari 15 angka'
    ],
    senderAddressRules: [
      v => !!v || 'Alamat Pengirim harus diisi',
      v => (v && v.length > 5) || 'Alamat Pengirim harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Alamat Pengirim tidak boleh lebih dari 255 huruf'
    ],
    receiverNameRules: [
      v => !!v || 'Penerima harus diisi',
      v => (v && v.length > 2) || 'Penerima harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Penerima tidak boleh lebih dari 50 huruf'
    ],
    receiverContactRules: [
      v => !!v || 'Kontak Penerima harus diisi',
      v => /^\d+$/.test(v) || 'Kontak Penerima harus berupa angka',
      v => (v && v.length > 9) || 'Kontak Penerima harus lebih dari 9 angka',
      v => (v && v.length < 16) || 'Kontak Penerima tidak boleh lebih dari 15 angka'
    ],
    receiverAddressRules: [
      v => !!v || 'Alamat Penerima harus diisi',
      v => (v && v.length > 5) || 'Alamat Penerima harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Alamat Penerima tidak boleh lebih dari 255 huruf'
    ],
    postalCodeRules: [
      v => !!v || 'Kode Pos harus diisi',
      v => (v && v.length == 5 &&  /^\d+$/.test(v)) || 'Kode Pos harus berupa 5 angka'
    ],
    originRules: [
      v => !!v || 'Asal harus dipilih'
    ],
    destinationRules: [
      v => !!v || 'Destinasi harus dipilih'
    ],
    descriptionRules: [
      v => !!v || 'Deskripsi harus diisi',
      v => (v && v.length > 2) || 'Deskripsi harus lebih dari 2 huruf',
      v => (v && v.length < 256) || 'Deskripsi tidak boleh lebih dari 255 huruf'
    ],
    valueRules: [
      v => !!v || 'Harga Barang harus diisi',
      v => /^\d+$/.test(v) || 'Harga Barang harus berupa angka'
    ],
    unitRules: [
      v => !!v || 'Jumlah Koli / Barang harus diisi',
      v => /^\d+$/.test(v) || 'Jumlah Koli / Barang harus berupa angka'
    ],
    city: [
      "Bandung",
      "Jakarta",
      "Tangerang",
      "Tasikmalaya",
      "Ciamis",
      "Cirebon",
      "Tegal",
      "Purwokerto",
      "Banjarnegara",
      "Kebumen",
      "Purworejo",
      "Yogyakarta",
      "Solo",
      "Sukoharjo",
      "Semarang",
      "Madiun",
      "Ngawi",
      "Banyuwangi",
      "Surabaya",
      "Sidoarjo",
      "Probolinggo",
      "Malang",
      "Kediri",
      "Nganjuk",
      "Jombang",
      "Mojokerto",
      "Kertosono",
      "Bojonegoro"
    ],
    newReceipt: {
      customerId: '',
      adminId: 1,
      senderName: '',
      senderContact: '',
      senderAddress: '',
      senderPic: '',
      receiverName: '',
      receiverContact: '',
      receiverAddress: '',
      postalCode: '',
      origin: '',
      destination: '',
      description: '',
      value: '',
      unit: '',
      price: 0,
    },
  }),
  mounted () {
    this.fetchCustomers()
    this.init()
  },
  computed: {
    ...mapGetters('customers', ['allCustomers']),
    ...mapGetters(['currentUser']),
    isCustomer () {
      return !!this.currentUser.customerId ? true : false
    },
    isAdmin () {
      return !!this.currentUser.adminId ? true : false
    },
    getAdminId () {
      return this.currentUser.adminId
    }
  },
  methods: {
    ...mapActions('receipts', ['createReceipt']),
    ...mapActions('customers',['fetchCustomers']),
    init () {
      if(this.isCustomer) {
        this.newReceipt.customerId = this.currentUser.customerId
        setTimeout(() => {
          this.autofill()
        }, 100);
      }
      else if (this.isAdmin) {
        this.newReceipt.adminId = this.currentUser.adminId
      }
    },
    autofill () {
      const customer = this.allCustomers.find(item => item.customerId == this.newReceipt.customerId)

      this.newReceipt.senderName = customer.customerName
      this.newReceipt.senderPic = customer.supervisorName
      this.newReceipt.senderContact = customer.supervisorContact
      this.newReceipt.senderAddress = customer.customerAddress
    },
    create () {
      if(this.$refs.form.validate()) {
        if (this.currentUser.adminId){
          this.newReceipt.adminId = this.currentUser.adminId;
        }
        this.createReceipt(this.newReceipt)
        window.location.pathname = '/receipts'
      }
    },
    backHome() {
      router.push({ path: '/receipts' })
    },
  },
}