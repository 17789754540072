import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import receipts from './modules/receipts'
import customers from './modules/customers'
import admins from './modules/admins'
import loginApi from '@/api/login'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const state = {
  currentUser: {},
  isLoggedIn: false
}

const getters = {
  currentUser: (state) => {
    return state.currentUser
  },
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
}

const mutations = {
  setCurrentUser (state, value) {
    state.currentUser = value
  },
  setLoggedIn (state, value) {
    state.isLoggedIn = value
  },
}

const actions = {
  fetchCurrentUser({ commit }, params) {
    loginApi.getCurrentUser(params).then((response) => {
      commit('setCurrentUser', response.data.tbMasterAdmin ? response.data.tbMasterAdmin : response.data.tbMasterCustomer)
      commit('setLoggedIn', response.data.isSuccess)
    })
  },
  logout({ commit }) {
    commit('setCurrentUser', {})
    commit('setLoggedIn', false)
  }
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    common,
    receipts,
    customers,
    admins
  },
  plugins: [createPersistedState({
    key: "currentUser",
    paths: ["currentUser", "isLoggedIn"]
  })]
})
