import Drawer from "@/components/dashboard/Drawer.vue"
import { mapActions, mapGetters } from "vuex"

export default {
  name: 'Home',

  components: {
    Drawer
  },

  data: () => ({
    
  }),
  
  created () {
    this.showDrawer()
  },
  computed: {
    ...mapGetters('common',['visibleDrawer'])
  },
  methods: {
    ...mapActions('common', ['showDrawer'])
  }
}