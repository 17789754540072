import router from '@/router'
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'editReceipt',
  data: () => ({
    valid: false,
    senderNameRules: [
      v => !!v || 'Pengirim harus dipilih'
    ],
    senderPicRules: [
      v => !!v || 'Penanggung Jawab harus diisi',
      v => (v && v.length > 2) || 'Penanggung Jawab harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Penanggung Jawab tidak boleh lebih dari 50 huruf'
    ],
    senderContactRules: [
      v => !!v || 'Kontak Pengirim harus diisi',
      v => /^\d+$/.test(v) || 'Kontak Pengirim harus berupa angka',
      v => (v && v.length > 9) || 'Kontak Pengirim harus lebih dari 9 angka',
      v => (v && v.length < 16) || 'Kontak Pengirim tidak boleh lebih dari 15 angka'
    ],
    senderAddressRules: [
      v => !!v || 'Alamat Pengirim harus diisi',
      v => (v && v.length > 5) || 'Alamat Pengirim harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Alamat Pengirim tidak boleh lebih dari 255 huruf'
    ],
    receiverNameRules: [
      v => !!v || 'Penerima harus diisi',
      v => (v && v.length > 2) || 'Penerima harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Penerima tidak boleh lebih dari 50 huruf'
    ],
    receiverContactRules: [
      v => !!v || 'Kontak Penerima harus diisi',
      v => /^\d+$/.test(v) || 'Kontak Penerima harus berupa angka',
      v => (v && v.length > 9) || 'Kontak Penerima harus lebih dari 9 angka',
      v => (v && v.length < 16) || 'Kontak Penerima tidak boleh lebih dari 15 angka'
    ],
    receiverAddressRules: [
      v => !!v || 'Alamat Penerima harus diisi',
      v => (v && v.length > 5) || 'Alamat Penerima harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Alamat Penerima tidak boleh lebih dari 255 huruf'
    ],
    postalCodeRules: [
      v => !!v || 'Kode Pos harus diisi',
      v => (v && v.length == 5 &&  /^\d+$/.test(v)) || 'Kode Pos harus berupa 5 angka'
    ],
    originRules: [
      v => !!v || 'Asal harus dipilih'
    ],
    destinationRules: [
      v => !!v || 'Destinasi harus dipilih'
    ],
    descriptionRules: [
      v => !!v || 'Deskripsi harus diisi',
      v => (v && v.length > 2) || 'Kode Pos harus lebih dari 2 huruf'
    ],
    valueRules: [
      v => !!v || 'Harga Barang harus diisi',
      v => /^\d+$/.test(v) || 'Harga Barang harus berupa angka'
    ],
    unitRules: [
      v => !!v || 'Jumlah Koli / Barang harus diisi',
      v => /^\d+$/.test(v) || 'Koli Barang harus berupa angka'
    ],
    city: [
      "Bandung",
      "Jakarta",
      "Tangerang",
      "Tasikmalaya",
      "Ciamis",
      "Cirebon",
      "Tegal",
      "Purwokerto",
      "Banjarnegara",
      "Kebumen",
      "Purworejo",
      "Yogyakarta",
      "Solo",
      "Sukoharjo",
      "Semarang",
      "Madiun",
      "Ngawi",
      "Banyuwangi",
      "Surabaya",
      "Sidoarjo",
      "Probolinggo",
      "Malang",
      "Kediri",
      "Nganjuk",
      "Jombang",
      "Mojokerto",
      "Kertosono",
      "Bojonegoro"
    ],
  }),
  mounted () {
    this.fetchReceipt(this.$route.params.code)
  },
  computed: {
    ...mapGetters('receipts', ['getReceipt']),
  },
  methods: {
    ...mapActions('receipts', ['fetchReceipt', 'editReceipt']),
    save () {
      if(this.$refs.form.validate()) {
        this.editReceipt({
          code: this.getReceipt.receiptNumber,
          data : {
            description: this.getReceipt.description,
            destination: this.getReceipt.destination,
            origin: this.getReceipt.origin,
            postalCode: this.getReceipt.postalCode,
            price: this.getReceipt.price,
            receiverAddress: this.getReceipt.receiverAddress,
            receiverContact: this.getReceipt.receiverContact,
            receiverName: this.getReceipt.receiverName,
            senderContact: this.getReceipt.senderContact,
            senderPic: this.getReceipt.senderPic,
            truckNumber: this.getReceipt.truckNumber,
            unit: this.getReceipt.unit,
            value: this.getReceipt.value
          }
        })
        window.location.pathname = '/receipts'
      }
    },
    backHome() {
      router.push({ path: '/receipts' })
    },
  }
}