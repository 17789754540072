import router from '@/router'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CustomerDetail',

  components: {
  },

  data: () => ({
    tempEnd: '',
    tempStart: '',
    page: 1, //currentPage
    pageCount: 0, //totalPage
    itemPerPage: 10,
    headers: [
      { text: 'Tanggal', value: 'createDate'},
      { text: 'Resi', value: 'receiptNumber' },
      { text: 'Asal', value: 'origin' },
      { text: 'Tujuan', value: 'destination' },
      { text: 'Pengirim', value: 'senderName' },
      { text: 'Penerima', value: 'receiverName' },
      { text: 'Harga', value: 'price' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    params: {
      pageRequest: '',
      senderName: '',
    }
  }),
  mounted () {
    this.fetchCustomer(this.$route.params.email)
    setTimeout(() => {
      this.fetchItems()
    },1000)
  },
  computed: {
    ...mapGetters('customers',['getCustomer']),
    ...mapGetters('receipts',['getFilteredReceipt']),
    ...mapGetters(['currentUser']),
    isCustomer () {
      return !!this.currentUser.customerId ? true : false
    }
  },
  methods: {
    ...mapActions('customers',['fetchCustomer']),
    ...mapActions('receipts',['filteredReceipt']),
    fetchItems () {
      this.params.pageRequest = '1'
      this.params.senderName = this.getCustomer.customerName
      this.filteredReceipt(this.params)
    },
    backHome () {
      router.push({ path: '/customers' })
    },
    getStatus (status) {
      if (status == 1) return "Menunggu Pickup"
      else if (status == 2) return 'Dalam Pengiriman'
      else if (status == 3) return 'Selesai'
    },
    getColor (status) {
      if (status == 1) return '#DC3545'
      else if (status == 2) return '#FFC107'
      else if (status == 3) return '#28A745'
    },
    detailItem (item) {
      window.open('/receipt/' + item.receiptNumber)
    },
    
    getFormat (item) {
      const date = new Date(item.createDate).getDate()
      const month = new Date(item.createDate).toLocaleString('default', { month: 'long' })
      const year = new Date(item.createDate).getFullYear()
      return (date + ' ' + month + ' ' + year)
    },
    getRouteCreate () {
      router.push('/create-receipt')
    }
  }
}