import config from '@/config'
import httpApi from '@/util/http-api'

export default {
  getAdmins () {
    return httpApi.getDataViaApi(config.api.admins)
  },
  getAdmin (username) {
    return httpApi.getDataViaApi(config.api.admin(username))
  },
  putAdmin (id, data) {
    httpApi.putDataViaApi(config.api.editAdmin(id), data)
  },
  postAdmin (data) {
    httpApi.postDataViaApi(config.api.createAdmin, data)
  },
  deleteAdmin (id) {
    httpApi.deleteDataViaApi(config.api.deleteAdmin(id))
  }
}