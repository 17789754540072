import config from '@/config'
import httpApi from '@/util/http-api'

export default {
  getReceipts (page) {
    return httpApi.getDataViaApi(config.api.receipts(page))
  },
  getReceipt (code) {
    return httpApi.getDataViaApi(config.api.receipt(code))
  },
  printReceipt (code) {
    return config.api.pdfReceipt(code)
  },
  updateStatus (code, data) {
    httpApi.putDataViaApi(config.api.updateStatus(code), data)
  },
  putReceipt (code, data) {
    httpApi.putDataViaApi(config.api.editReceipt(code), data)
  },
  postReceipt (data) {
    httpApi.postDataViaApi(config.api.createOrder, data)
  },
  deleteReceipt (code) {
    httpApi.deleteDataViaApi(config.api.deleteReceipt(code))
  }
}