import router from '@/router'
import { mapActions } from "vuex";

export default {
  name: 'CreateCustomer',

  data: ( ) => ({
    valid: false,
    showPass: false,
    nameRules: [
      v => !!v || 'Nama / Perusahaan harus diisi',
      v => (v && v.length > 2) || 'Nama / Perusahaan harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Nama / Perusahaan tidak boleh lebih dari 50 huruf'
    ],
    addressRules: [
      v => !!v || 'Alamat harus diisi',
      v => (v && v.length > 5) || 'Alamat harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Alamat tidak boleh lebih dari 255 huruf'
    ],
    emailRules: [
      v => !!v || 'Email harus diisi',
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail harus sesuai',
      v => (v && v.length < 51) || 'Email tidak boleh lebih dari 50 huruf'
    ],
    passwordRules: [
      v => !!v || 'Kata Sandi harus diisi',
      v => (v && v.length > 5) || 'Kata sandi harus lebih dari 5 huruf',
      v => (v && v.length < 256) || 'Kata Sandi tidak boleh lebih dari 255 huruf'
    ],
    picRules: [
      v => !!v || 'Penanggung Jawab harus diisi',
      v => (v && v.length > 2) || 'Penanggung Jawab harus lebih dari 2 huruf',
      v => (v && v.length < 51) || 'Penanggung Jawab tidak boleh lebih dari 50 huruf'
    ],
    phoneRules: [
      v => !!v || 'Nomor HP harus diisi',
      v => /^\d+$/.test(v) || 'Nomor HP harus berupa angka',
      v => (v && v.length > 9) || 'Nomor HP harus lebih dari 9 angka',
      v => (v && v.length < 16) || 'Nomor HP tidak boleh lebih dari 15 angka'
    ],
    newCustomer: {
      customerName: '',
      customerAddress: '',
      customerEmail: '',
      customerPass: '',
      supervisorName: '',
      supervisorContact: ''
    },
    
  }),
  methods: {
    ...mapActions('customers', ['createCustomer']),
    create () {
      if(this.$refs.form.validate()) {
        this.createCustomer(this.newCustomer)
        window.location.pathname = '/customers'
      }
    },
    backHome() {
      router.push({ path: '/customers' })
    },
  }
}