import axios from 'axios'

export const state = {
  visibleDrawer: true,
  visibleButton: true,
  pricelist: []
}
export const getters = {
  visibleDrawer: (state) => {
    return state.visibleDrawer
  },
  visibleButton: (state) => {
    return state.visibleButton
  },
  pricelist: (state) => {
    return state.pricelist
  }
}
export const mutations = {
  setDrawer (state, value) {
    state.visibleDrawer = value
  },
  setButton (state, value) {
    state.visibleButton = value
  },
  setPricelist (state, value) {
    state.pricelist = value
  }
}
export const actions = {
  showDrawer ({ commit }) {
    commit('setDrawer', true)
  },
  hideDrawer ({ commit }) {
    commit('setDrawer', false)
  },
  showButton ({ commit }) {
    commit('setButton', true)
  },
  hideButton ({ commit }) {
    commit('setButton', false)
  },
  fetchPricelist ({ commit }) {
    axios.get('pricelist.json').then((response) => {
      commit('setPricelist', response.data.pricelist)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}